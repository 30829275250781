import { ApiHelper } from "./helper/ApiHelper";
const apiHelper = new ApiHelper();
export class UserManagement {
    constructor() {}
    getAllUserByClient(clientId, currentPage, perPage) {
        const uri = `user-module/v1/created-by?email=${clientId}&page=${currentPage}&size=${perPage}`;
        return apiHelper.get(uri);
    }
    getBrandBySubId() {
        const uri = `user-module/v1/get-brands?id=${sessionStorage.getItem(
      "subId"
    )}&userId=${sessionStorage.getItem("userId")}`;
        return apiHelper.get(uri);
    }
    registerUser(data) {
        const uri = `user-module/register_user`;
        return apiHelper.post(uri, data);
    }
    sortedField(data, currentPage, perPage) {
        const uri = `user-module/sort_user?page=${currentPage}&size=${perPage}&field=${data.field}&email=${data.email}&direction=${data.direction}`;
        return apiHelper.get(uri);
    }
    getsearchUserName(userName, currentPage, perPage, clientId) {
        const uri = `user-module/search_users?userName=${userName}&email=${clientId}&page=${currentPage}&size=${perPage}`;
        return apiHelper.get(uri);
    }
    createNewBrand(data) {
        const uri = `user-module/v1/brand`;
        return apiHelper.post(uri, data);
    }
    deleteUser(data) {
        const uri = `user-module/delete`
        return apiHelper.delete(uri, data)
    }
    getAllBrands(orgnumber,currentPage,perPage){
        const uri = `user-module/v1/brands?id=${orgnumber}&page=${currentPage}&size=${perPage}`
        return apiHelper.get(uri)
    }
    getUserBrands(clientId,currentPage,perPage){
        const uri = `user-module/v1/brands/email?email=${clientId}&page=${currentPage}&size=${perPage}`
        return apiHelper.get(uri)
    }
    getAllBrandList(){
        const uri = `user-module/v1/brand-orgnumber?orgNumber=${sessionStorage.getItem('subId')}`
        return apiHelper.get(uri)
    }
    updateUser(data) {
        const uri = `user-module/update_user`
        return apiHelper.patch(uri, data)
    }
    searchBrandByBrandName(searchText,currentPage,perPage){
        const uri = `user-module/v1/brand-name?brandName=${searchText}&page=${currentPage}&size=${perPage}`
        return apiHelper.get(uri)
    }
    getDomainList() {
        const uri = `health-monitor-module/v1/domain`;
        return apiHelper.get(uri);
      }
      getSwitchUserList(email,currentPage, perPage){
          const uri = `user-module/v1/org-details?email=${email}&page=${currentPage}&size=${perPage}`
          return apiHelper.get(uri)
      }
}